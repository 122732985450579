<script setup lang="ts">
const { isImpersonating } = useImpersonation();

useHead({
  bodyAttrs: {
    class: 'bg-blue-300',
  },
});
</script>

<template>
  <div>
    <div class="sticky top-0 z-50 w-full">
      <TnImpersonateBanner v-if="isImpersonating" />
    </div>
    <div
      class="relative z-0 flex min-h-screen w-screen flex-col items-center overflow-hidden"
    >
      <div class="absolute inset-0 z-0 flex min-h-screen flex-col">
        <div class="h-1/3 w-screen border-b border-black bg-blue-300">
          <img
            src="@/assets/review-cloud-left.svg"
            alt="Cloud"
            class="absolute left-0 top-14 sm:top-5"
          />
          <img
            src="@/assets/review-cloud-right.svg"
            alt="Cloud"
            class="absolute right-0 top-5 hidden sm:block"
          />
        </div>
        <div class="relative w-full flex-grow bg-green-500">
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute left-64 top-28"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute right-24 top-44"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute left-11 top-52"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute left-64 top-80"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute right-56 top-[345px]"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute bottom-36 left-40"
          />
          <img
            src="@/assets/review-bg-pattern.svg"
            alt="Pattern"
            class="absolute bottom-12 right-[495px]"
          />
        </div>
      </div>

      <div class="flex w-full flex-col items-center pb-6 sm:max-w-[640px]">
        <slot name="header">
          <div class="pt-6"></div>
        </slot>
        <div
          class="relative z-30 mx-6 flex min-h-[640px] flex-col items-center justify-between rounded-3xl border border-b-4 border-black bg-white px-4 py-6 sm:min-w-[640px] sm:p-10"
        >
          <div class="w-full">
            <slot name="contentHeader"></slot>
            <slot name="content" />
          </div>
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
